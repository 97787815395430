import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiEndpoints } from 'Utils/ApiEndpoints';

const baseUrl = process.env.REACT_APP_BASE_URL
const baseQueryWithReauth = async (args, api, extraOptions) => {
    let result = await fetchBaseQuery({
        baseUrl: baseUrl,
        prepareHeaders: (headers, { getState }) => {
            const token = getState().auth?.authData?.token;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
    })(args, api, extraOptions);

    if (result?.error && result?.error.status === 401) {
        window.location.href = '/'
    }
    return result;
};

export const userApi = createApi({
    reducerPath: 'userApi',
    baseQuery: baseQueryWithReauth,
    tagTypes: ['User'],
    endpoints: (builder) => ({
        getUserData: builder.query({
            query: ({ page, limit, searchData }) => ({
                url: `${apiEndpoints?.userGetUrl}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        getUserDataDetails: builder.query({
            query: (userId) => ({
                url: `${apiEndpoints?.userDetailsUrl}?user_id=${userId}`,
                method: 'GET',
            }),
            providesTags: ['User'],
        }),
        updateUserStatus: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.userStatusUrl,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updateAdminProfile: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.adminProfileUrl,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        getDashboardData: builder.query({
            query: () => ({
                url: `${apiEndpoints?.userDashboardUrl}`,
                method: 'GET',
            }),
        }),
        getUserProfile: builder.query({
            query: () => ({
                url: apiEndpoints?.profileUrl,
                method: 'GET',
            }),
        }),
        getGroupsData: builder.query({
            query: ({ page, limit, searchData }) => ({
                url: `${apiEndpoints?.groupGetUrl}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['Group'],
        }),
        getGrooupDataDetails: builder.query({
            query: (groupId) => ({
                url: `${apiEndpoints?.groupDetailsUrl}?group_pool_id=${groupId}`,
                method: 'GET',
            }),
            providesTags: ['Group'],
        }),
        getGrooupActivity: builder.query({
            query: (groupId) => ({
                url: `${apiEndpoints?.getallgroupactivity}?group_pool_id=${groupId}`,
                method: 'GET',
            }),
            providesTags: ['Group'],
        }),
        getWagersDataDetails: builder.query({
            query: (groupId) => ({
                url: `${apiEndpoints?.groupWagersUrl}?wager_id=${groupId}`,
                method: 'GET',
            }),
            providesTags: ['Group'],
        }),
        getWagerData: builder.query({
            query: ({ page, limit, searchData }) => ({
                url: `${apiEndpoints?.wagerGetUrl}?page=${page}&limit=${limit}&search_key=${searchData ?? ''}`,
                method: 'GET',
            }),
            providesTags: ['Wager'],
        }),
        updateGroupStatus: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.groupStatusUrl,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
        updateWagerStatus: builder.mutation({
            query: (data) => ({
                url: apiEndpoints?.wagerStatusUrl,
                method: "PUT",
                body: data
            }),
            invalidatesTags: ['User'],
        }),
    })
});

export const {
    useGetUserDataQuery, 
    useGetUserDataDetailsQuery, 
    useGetGrooupDataDetailsQuery,
    useUpdateUserStatusMutation, 
    useGetDashboardDataQuery,
    useGetUserProfileQuery,
    useGetGroupsDataQuery,
    useGetGrooupActivityQuery,
    useGetWagerDataQuery,
    useUpdateGroupStatusMutation, 
    useGetWagersDataDetailsQuery,
    useUpdateWagerStatusMutation, 
    useUpdateAdminProfileMutation
} = userApi;
