import { createSlice } from "@reduxjs/toolkit";
import { commonApi } from "Rtk/services/common";

const commonSlice = createSlice({
  name: "common",
  initialState: {  commonData:{} },
  reducers: {
    clearCommonData: (state) => {
      state.commonData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      commonApi.endpoints.getCommonData.matchFulfilled,
      (state, { payload }) => {
        state.commonData = payload.data;
      }
    );
  }
});

export const {clearAuthData } = commonSlice.actions;

export default commonSlice.reducer;

// export const selectCurrentUser = (state) => state.auth.user;
