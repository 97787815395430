import { createSlice } from "@reduxjs/toolkit";
import { userApi } from "Rtk/services/user";

const userSlice = createSlice({
  name: "user",
  initialState: {  userData:{} },
  reducers: {
    clearUserData: (state) => {
      state.userData = {};
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      userApi.endpoints.getUserData.matchFulfilled,
      (state, { payload }) => {
        state.userData = payload.data;
      }
    );
  }
});

export const {clearUserData } = userSlice.actions;

export default userSlice.reducer;

// export const selectCurrentUser = (state) => state.auth.user;
