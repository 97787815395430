import { createSlice } from "@reduxjs/toolkit";
import { authApi } from "../../services/auth"

const authSlice = createSlice({
  name: "auth",
  initialState: { authData:{} },
  reducers: {
    clearAuthData: (state) => {
      state.authData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      authApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        state.authData = payload.data;
      }
    );
  }
});

export const {clearAuthData } = authSlice.actions;

export default authSlice.reducer;

// export const selectCurrentUser = (state) => state.auth.user;
