export const apiEndpoints = {
    loginUrl:`admin/auth/login`,
    signUrl:`admin/auth/signup`,
    forgetUrl :'admin/auth/forgot_password',
    otpVerifyUrl : "admin/auth/verify_otp",
    resendOtpUrl : "admin/auth/resend_otp",
    changePasswordUrl : "admin/auth/change_password",
    resetPasswordUrl : "admin/auth/reset_password",
    profileUrl : "admin/auth/details",
    commonContentUrl : "common/common_content",
    commonContentUpdateUrl : "admin/common/common_content",
    faqGetUrl :"common/questions", 
    faqCommonUrl : "admin/common/question",
    userGetUrl : "admin/user/list", 
    userDetailsUrl : "admin/user/details", 
    groupDetailsUrl:"admin/user/get_group_detail",
    getallgroupactivity:"/admin/user/getallgroupactivity",
    userStatusUrl : "admin/user/status",
    userDashboardUrl : "admin/user/dashboard",
    groupGetUrl : "admin/user/group_list", 
    wagerGetUrl : "admin/user/wager_list",
    groupStatusUrl : "admin/user/group_status",
    groupWagersUrl:"admin/user/get_wager_detail",
    wagerStatusUrl : "admin/user/wager_status",
    adminProfileUrl : "/admin/auth/profile",
}